import ApiService from "../../api/api";
import {changeAlertStatusAC, changeLoaderMessageAC, changeLoaderStatusAC} from "../reducers/CommonReducer";
import {dispatcherErrorThunk} from "./common-thunk";
import {setCuttingCardInProject, setOrderCopyProject, setOrderProjectDataAC} from "../reducers/OrderReducer";
import {getTranslateMessageAPI} from "../../helpers/helpers";
import {ShortOrderHtml} from "../../printHtml/ShortOrderHtml";
import {shortOrderFormWindowHtml} from "../../printHtml";
import {CuttingAPI} from "../../api/CuttingAPI";
import {actionsCuttingChart} from "../reducers/CuttingChartReducer";
import {getCuttingCardPreviewsThunk} from "./images-thunk";
import {LS_LINK_T0_3D_CARD} from "../../constants";

const API = new ApiService();
/**
 * dispatchedCuttingCardThunk. Получить карты кроя
 * @param {number| string} order_id -
 */
export const dispatchedCuttingCardThunk = (order_id) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Загружаем карты кроя...")));

        const cutting_card = await API._getCuttingCard(order_id);
        if (cutting_card?.hasOwnProperty('error') || API._errors) {
            let error_msg = API._getErrors() || cutting_card?.error;
            throw  Error(error_msg);
        }
        dispatch(setCuttingCardInProject(cutting_card?.cutting_card))
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}

export const generateShortOrderFullHtml = (order = null) => (dispatch, getState) => {
    let logo = getState().commons?.ruleSettingsProject?.logoUrl;
    let brand_company = getState().commons?.ruleSettingsProject?.name;
    const currencies = getState().commons?.currencies || [];
    const currency = currencies?.find(item => Number(item.id) === Number(order?.currency))?.name ?? '';
    let band_calculate = order?.calculate?.material?.filter(item => item.type === "band");
    let payload = {
        ...order, band_calculate: band_calculate, currency: currency
    }
    const content_html = new ShortOrderHtml().generateContentHtml({
        order: payload, full: true, logo: logo
    });
    shortOrderFormWindowHtml.openWindowPrint({content: content_html, order: payload});

}

export const generateShortOrderDetailingHtml = (order) => async (dispatch, getState) => {
    let logo = getState().commons?.ruleSettingsProject?.logoUrl;
    let brand_company = getState().commons?.ruleSettingsProject?.name;
    let responseParts = await API.getAllPartSizes(order.part, order.bands_type, order.material, order.band, order?.client);
    const content_html = new ShortOrderHtml().generateContentHtml({
        order: {
            ...order, part: responseParts
        }, full: false, logo: logo, company_name: brand_company
    });

    shortOrderFormWindowHtml.openWindowPrint({content: content_html, order: order});

}

/**
 * ICutting Получить карыт кроя новая версия.
 *
 * @param {object} order  -
 */

export const getCuttingCardByOrderIdThunk = (order=null) => async (dispatch, getState) => {
    try {
        const version = getState().commons.data.version;
        dispatch(changeLoaderStatusAC(true));
        if(!order) {
            throw new Error("The order should not be empty");
            return
        }

        const responseSaveOrder = await API.saveProject(version, order);
        if(!responseSaveOrder.is_save){
            if (API._errors) {
                throw new Error(API._getErrors());
                return
            }
        }
        const responseCutting = await CuttingAPI.getCuttingByOrderId(order.id);

        if (responseCutting?.hasOwnProperty('error') || responseCutting?.error) {
            dispatch(dispatcherErrorThunk(responseCutting))
            return
        }
        if (responseCutting && responseCutting?.link_to_cad) {
            localStorage.setItem(LS_LINK_T0_3D_CARD, responseCutting?.link_to_cad)
        }


        await dispatch(getCuttingCardPreviewsThunk(responseCutting.order));
        dispatch(setOrderProjectDataAC(responseCutting.order));
        dispatch(setOrderCopyProject(responseCutting.order));
        // dispatch(setCuttingCardInProject(responseCutting?.order?.cutting_card));
        dispatch(actionsCuttingChart.onToggleCuttingPage(true));
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
/**
 *ICuttingGL получаем в ответ ссылку, по которой переходим
 * @param {number} order -
 */
export const getCuttingCardGlByOrderIdThunk = (order=null) => async (dispatch, getState) => {
    try {
        const version = getState().commons.data.version;
        if(!order) {
            throw new Error("The order  should not be empty");
            return
        }
        dispatch(changeLoaderStatusAC(true));
        const responseSaveOrder = await API.saveProject(version, order);
        if(!responseSaveOrder.is_save){
            if (API._errors) {
                throw new Error(API._getErrors())
            }
        }
        const response = await CuttingAPI.getCuttingGlLinkByOrderId(order.id);
        if (response?.hasOwnProperty('error') || response?.error) {
            dispatch(dispatcherErrorThunk(response))
            return
        }
        let targetBlank = Number(response?.blank);
        if(!response?.link) {
          throw new Error("missing property - link ")
        }
        if(targetBlank){
            window.open(response?.link, '_blank');

        }else{
            window.open(response?.link,"_self")
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}