import React, {useEffect, useMemo, useState} from 'react';
import IconCutting from "../../../../Icons/IconCutting";
import {saveOrderProjectDataThunk, setOrderProjectDataAC} from "../../../../store/reducers/OrderReducer";
import {checkProjectBeforeSaveProject, getGiblabDataThunk} from "../../../../store/reducers/CommonReducer";
import {useDispatch, useSelector} from "react-redux";
import {getCuttingCardDT} from "../../../../selectors/cutting-selector";
import SubMemu from "./SubMemu";
import mainApi from "../../../../api/MainApi";
import {getProjectVersionCode} from "../../../../selectors/common-selector";
import {
    dispatchedCuttingCardThunk,
    getCuttingCardByOrderIdThunk,
    getCuttingCardGlByOrderIdThunk
} from "../../../../store/thunks/cutting-thunk";
import {LS_CUTTING_CARD_TYPE_VERSION_DEFAULT} from "../../../../constants";

const API = new mainApi();
const CuttingCartNavigation = ({triggerApiPackAndSaveStoreOrder, getT}) => {
    const cuttingCard = useSelector(getCuttingCardDT);
    const orderGlobal = useSelector((state) => state.order.order);
    const dispatch = useDispatch();
    const client_id = useSelector(state => state.auth.user.id);
    const firm = localStorage.getItem('ifurn_service_main_user_firm')
    const version_code = useSelector(getProjectVersionCode)
    const isEditOrderStatus = Number(orderGlobal?.status) === 1;
    const [isKeyPress, setKeyPress] = useState(false);
    useEffect(() => {
        const onPressKey = (event) => {
            if (event.altKey && event.key === 'b') {
                setKeyPress(prev => !prev);
            }
        }
        window.addEventListener('keydown', onPressKey)
        return () => window.removeEventListener('keydown', onPressKey)
    }, []);

    const uri = useMemo(() => {
        return `${API._baseIfurn}/cutting?client=${client_id}&order=${version_code.code}&firm=${firm}&clean=1`
    }, [version_code, client_id])
    const handlerOpenWindowCutting = async () => {
        if (orderGlobal?.cutting_card_in) await dispatchedCuttingCardThunk(orderGlobal.id)
        await dispatch(checkProjectBeforeSaveProject(null, async () => {
            let isSave = await dispatch(saveOrderProjectDataThunk(false, false, false))
            if (!isSave) return
            window.open(uri, "_self")
        }));
    }

    const openHandlerCuttingCards = async () => {
        sessionStorage.removeItem('calculate');
        dispatch(getGiblabDataThunk("get-cuttings"));
    }


    const openCuttingChartPage = async () => {
        let is_open_type_cutting = localStorage.getItem(LS_CUTTING_CARD_TYPE_VERSION_DEFAULT)
        if (!!Number(is_open_type_cutting)) {
            await handlerOpenWindowCutting();
            return
        }
        if (!isEditOrderStatus) {
            dispatch(getGiblabDataThunk("cutting", null, false))
            return
        }
        if (!!cuttingCard) {
            if (!Array.isArray(cuttingCard) && Object.keys(cuttingCard).length > 1) {
                orderGlobal.cutting_card = [];
            }
        }
        await triggerApiPackAndSaveStoreOrder(orderGlobal);
        dispatch(getGiblabDataThunk("cutting"))

    };
    const openICutting = async () => {
        let order = await triggerApiPackAndSaveStoreOrder(orderGlobal);
        dispatch(getCuttingCardByOrderIdThunk(order))
    }
    const openWindowICuttingGL = async () => {
        let order = await triggerApiPackAndSaveStoreOrder(orderGlobal);
        dispatch(getCuttingCardGlByOrderIdThunk(order))

    }
    return (
        <>
            <span onClick={openCuttingChartPage} className={`cutting_btn`}> <IconCutting/></span>
            {(isKeyPress && isEditOrderStatus) && <SubMemu getT={getT} openHandlerCutting={handlerOpenWindowCutting}
                                                           openHandlerCuttingCards={openHandlerCuttingCards}
                                                           openICutting={openICutting}
                                                           openWindowICuttingGL={openWindowICuttingGL}/>}
        </>
    );
};

export default CuttingCartNavigation;